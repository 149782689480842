import React from 'react';

import Layout from '../components/layout';
import SearchBar from '../components/searchbar';
import SEO from '../components/seo';

import './verb-post.scss';

const VerbPostTemplate = ({ pageContext, location }) => {
    return (
        <Layout location={location} title={'Slovo App'}>
            <SEO
                title={
                    pageContext.name.charAt(0).toUpperCase() +
                    pageContext.name.slice(1)
                }
                description={
                    'Conjugations list for ' + pageContext.name + ' conjugatie'
                }
            />
            <div className="word-page">
                <header>
                    <h1 className="verb-post-header">
                        {pageContext.name.charAt(0).toUpperCase() +
                            pageContext.name.slice(1)}
                    </h1>
                </header>
                <SearchBar />
                <section className="verb-section">
                    <table>
                        <tbody>
                            <tr>
                                <th></th>
                                <th>
                                    Presens <br />
                                    <span className="second-meaning">
                                        *onvoltooid tegenwoordige tijd
                                    </span>
                                </th>
                                <th>
                                    Imperfectum <br />
                                    <span className="second-meaning">
                                        *onvoltooid <br /> verleden tijd
                                    </span>
                                </th>
                                <th>
                                    Perfectum <br />
                                    <span className="second-meaning">
                                        *voltooid tegenwoordige tijd
                                    </span>
                                </th>
                            </tr>

                            <tr className="grey-bg">
                                <td>ik</td>
                                <td>{pageContext.present_1}</td>
                                <td>{pageContext.past_1}</td>
                                <td>{pageContext.pre_perfect_1}</td>
                            </tr>
                            <tr>
                                <td>jij, je, u</td>
                                <td>{pageContext.present_2}</td>
                                <td>{pageContext.past_2}</td>
                                <td>{pageContext.pre_perfect_2}</td>
                            </tr>
                            <tr className="grey-bg">
                                <td>hij, zij, het</td>
                                <td>{pageContext.present_3}</td>
                                <td>{pageContext.past_3}</td>
                                <td>{pageContext.pre_perfect_3}</td>
                            </tr>
                            <tr>
                                <td>wij</td>
                                <td>{pageContext.present_4}</td>
                                <td>{pageContext.past_4}</td>
                                <td>{pageContext.pre_perfect_4}</td>
                            </tr>
                            <tr className="grey-bg">
                                <td>jullie</td>
                                <td>{pageContext.present_5}</td>
                                <td>{pageContext.past_5}</td>
                                <td>{pageContext.pre_perfect_5}</td>
                            </tr>
                            <tr>
                                <td>zij, ze</td>
                                <td>{pageContext.present_6}</td>
                                <td>{pageContext.past_6}</td>
                                <td>{pageContext.pre_perfect_6}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <hr />
            </div>
        </Layout>
    );
};

export default VerbPostTemplate;
